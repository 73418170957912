import React, { Fragment } from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { SectionContainerGridFourCols, SectionContainerGridThreeCols, SectionContainerGridTwoCols, SectionTitleContainer, SectionWrapperSmall } from "../components/styles/Sections"
import ArticleComponent from "../components/ArticleComponent"
import Footer from "../components/footer"
import BreadCrumbs from "../components/breadcrumbs"
import Bio from "../components/bio"

const BlogIndex = ({
  data,
  location,
  pageContext: { nextPagePath, previousPagePath },
}) => {
  const posts = data.allWpNyheter.nodes;
  const guides = data.allWpGuider.nodes;
  const authors = data.allWpUser.nodes;
  const campaigns = data.allWpKampanj.nodes;
  const archiveName = location.pathname.replaceAll('/', '');

  /* CUSTOM ARRAY TO RENDER BREADCRUMBS AS IT IS AN ARCHIVE PAGE */
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  
  const crumbs = [ 
    {
    "text": "Hem",
    "url": "https://svenskaonlinecasinon.se"
    },
    {
      "text": capitalizeFirstLetter(archiveName),
      "url": "/" + archiveName
    }
  ]

  const seoTitle = "Casino" + archiveName + " | Läs alla " + archiveName + " gällande online casinon";
  const metaDescription = "Här hittar du alla" + archiveName + "från casinobranschen. Våra expterter uppdaterar sidan dagligen."

  return (
    <Layout isHomePage>
      <Seo title={seoTitle} description={metaDescription} />
      <BreadCrumbs items={crumbs} hideDate={true} />

      <SectionWrapperSmall>
        {location.pathname === "/nyheter" && (
          <Fragment>
            <SectionTitleContainer>
              <h1>Nyheter</h1>
                <p>Här hittar du alla nyheter relaterade till Svenska Online Casinon. 
                  Våra erfarna skribenter uppdaterar arkivet ständigt för att du alltid ska kunna hålla dig uppdaterad med det senaste inom den Svenska casinomarknaden.  
                </p>
            </SectionTitleContainer>
            <SectionContainerGridThreeCols>
              {posts.map((item, i) => (
                <ArticleComponent item={item} key={i} />
              ))}
            </SectionContainerGridThreeCols>
          </Fragment>
        )}
        {location.pathname === "/guider" && (
          <Fragment>
            <SectionTitleContainer>
              <h1>Guider</h1>
              <p>Här hittar du informativa guider relaterade till Svenska Online Casinon. Dessa guider är framtagna av våra casinoexpterer för att du enkelt ska kunna komma igång och spela.
                Guiderna kan vara bra att läsa igenom om du är en ny spelare eller en mer erfaren spelare som vill fördjupa sig lite mer.  
               </p>
            </SectionTitleContainer>
            <SectionContainerGridThreeCols>
              {guides.map((item) => (
                <ArticleComponent item={item} />
              ))}
            </SectionContainerGridThreeCols>
          </Fragment>
        )}
        {location.pathname === "/kampanjer" && (
          <Fragment>
            <SectionTitleContainer>
              <h1>Kampanjer</h1>
              <p>Här hittar du alla aktuella casino kampanjer hos online casinon. Kampanjerna uppdateras ständigt så håll utkik här för de senaste erbjudandena!</p>
            </SectionTitleContainer>
            <SectionContainerGridFourCols>
              {campaigns.map((item) => (
                <ArticleComponent item={item} />
              ))}
            </SectionContainerGridFourCols>
          </Fragment>
        )}
        {location.pathname === "/skribenter" && (
          <Fragment>
            <h1>Våra skribenter</h1>
            <p>Här hittar du teamet bakom Svenskaonlinecasinon.se som underhåller vår sida och ser till att våra användare alltid får tillgång de senaste recensionerna, guiderna och nyheterna.</p>
            <SectionContainerGridTwoCols>
              {authors.map((item) => (
                <Bio author={item} />
              ))}
            </SectionContainerGridTwoCols>
          </Fragment>
        )}
      </SectionWrapperSmall>

      {previousPagePath && (
        <>
          <Link to={previousPagePath}>Previous page</Link>
          <br />
        </>
      )}
      {nextPagePath && <Link to={nextPagePath}>Next page</Link>}
      <Footer />
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query WordPressNewsArchive {
    allWpNyheter(
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        excerpt
        uri
        date(locale: "sv-SE", formatString: "MMMM DD, YYYY")
        title
        seo {
          breadcrumbs {
            url
            text
          }
        }
        tags {
          nodes {
            name
            uri
          }
        }
        categories {
          nodes {
            name
            uri
          }
        }
        author {
          node {
            name
            uri
            avatar {
              url
            }
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: BLURRED
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
      }
    }
    allWpKampanj(
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        uri
        date(locale: "sv-SE", formatString: "MMMM DD, YYYY")
        title
        seo {
          breadcrumbs {
            url
            text
          }
        }
        author {
          node {
            name
            uri
            avatar {
              url
            }
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: BLURRED
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
      }
    }
    allWpGuider(
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        excerpt
        uri
        date(locale: "sv-SE", formatString: "MMMM DD, YYYY")
        title
        seo {
          breadcrumbs {
            url
            text
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: BLURRED
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
      }
    }
    allWpUser {
      nodes {
        name
        slug
        description
        avatar {
          url
        }
      }
    }
  }
`
